/**
*   File:   ./src/assets/base.scss
*
*   Subject:    The base SCSS file of the application
*/

// BOOTSTRAP 4 VARIABLES

@import "components/bootstrap4/functions";
@import "components/bootstrap4/variables";
@import "components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "components/bootstrap4/root";
@import "components/bootstrap4/reboot";
@import "components/bootstrap4/type";
@import "components/bootstrap4/images";
@import "components/bootstrap4/code";
@import "components/bootstrap4/grid";
@import "components/bootstrap4/tables";
@import "components/bootstrap4/forms";
@import "components/bootstrap4/buttons";
@import "components/bootstrap4/transitions";
@import "components/bootstrap4/dropdown";
@import "components/bootstrap4/button-group";
@import "components/bootstrap4/input-group";
@import "components/bootstrap4/custom-forms";
@import "components/bootstrap4/nav";
@import "components/bootstrap4/navbar";
@import "components/bootstrap4/card";
@import "components/bootstrap4/breadcrumb";
@import "components/bootstrap4/pagination";
@import "components/bootstrap4/badge";
@import "components/bootstrap4/jumbotron";
@import "components/bootstrap4/alert";
@import "components/bootstrap4/progress";
@import "components/bootstrap4/media";
@import "components/bootstrap4/list-group";
@import "components/bootstrap4/close";
@import "components/bootstrap4/toasts";
@import "components/bootstrap4/modal";
@import "components/bootstrap4/tooltip";
@import "components/bootstrap4/popover";
@import "components/bootstrap4/carousel";
@import "components/bootstrap4/spinners";
@import "components/bootstrap4/utilities";
@import "components/bootstrap4/print";
// LAYOUT

@import "layout/layout";

// UTILS

@import "utils/helpers";
@import "utils/backgrounds";
@import "utils/animate";
@import "utils/comps-animations";

// ELEMENTS

@import "elements/buttons";
@import "elements/dropdown";
@import "elements/navs";
@import "elements/badges";
@import "elements/cards";
@import "elements/tabs";
@import "elements/accordions";
@import "elements/modals";
@import "elements/loaders";
@import "elements/progressbar";
@import "elements/timeline";
@import "elements/listgroup";
@import "elements/forms";
@import "elements/pagination";
@import "elements/chat";
@import "elements/forms-wizard";

// DASHBOARD BOXES

@import "widgets/chart-boxes/chart-boxes";
@import "widgets/content-boxes/content-boxes";
@import "widgets/profile-boxes/profile-boxes";

// PAGES

@import "pages/userpages";

// Applications

@import "applications/applications-base";

// COMPONENTS

// Perfect Scrollbar
@import "components/perfect-scrollbar/perfect-scrollbar";

// RC Tabs
@import "components/rc-tabs/rc-tabs";

// Loading Buttons
@import "components/loading-buttons/loading-buttons";

// Hamburger Button
@import "components/hamburgers/hamburgers";

// Drawer
@import "components/drawer/drawer";

// Notifications
@import "components/notifications/sweetalerts";
@import "components/notifications/toastify";

// Modals
@import "components/modals/rodal";

// Progress Bar
@import "components/progress-bar/progress-bar";

// Slick Carousel
@import "components/slick-carousel/slick/slick";

// Block loading
@import "components/block-loading/block-loading";

// Popovers & Tooltips
@import "components/popovers-tooltips/popovers-tooltips";

// Sticky Elements
@import "components/sticky-elements/sticky-elements";

// Calendar
@import "components/calendar/calendar";

// Splitters
@import "components/splitters/splitters";

// Tree View
@import "components/tree-view/tree-view";

// Tables
@import "components/tables/tables";

// Icons
@import "components/icons/linearicons";
@import "components/icons/p7icons";

// FORMS

@import "components/forms/datepicker/datepicker";
@import "components/forms/rangeslider/rangeslider";
@import "components/forms/multiselect/multiselect";
@import "components/forms/wysiwyg/wysiwyg";
@import "components/forms/toggle-switch/switch";
@import "components/forms/dropzone/dropzone";
@import "components/forms/typeahead/typeahead";

// REACT WIDGETS

@import "components/react-widgets/scss/react-widgets";

// DEMO

@import "demo-ui/demo";

// Responsive

@import "layout/responsive/responsive-base";

.a-no-style {
    color: #343a40;
}

.a-no-style:hover {
    text-decoration: none;
}

.app-table {
    border:         1px solid #ddd;
    display:        flex;
    flex-direction: column;
    overflow:       hidden;
}

.app-table-border-inline {
    border: 0;
}

.app-table-checkbox {
    height: 15px;
}



.app-table-content {
    height:         calc(100vh - 60px - 120px - 30px - 30px - 40px - 48.5px - 52px);
    display:        flex;
    flex-direction: column;
}

.app-table-controls {
    box-shadow:         0 -2px 7px 0 rgba(0, 0, 0, 0.07);
    border-top:         2px solid rgba(0, 0, 0, 0.1);
    height:             40px;
    display:            flex;
    flex-direction:     row;
    background-color:   #eee;
}

.app-table-controls-main{
    padding-left:       20px;
    padding-right:      20px;
    background-color:   white;
    display:            flex;
    flex-direction:     row;
    align-items:        center;
}

.app-table-controls-num_pages {
    padding-right: 25px;
}

.app-table-controls-page_label {
    margin-right: 10px;
}

.app-table-controls-page {
    padding-right: 8px;
}

.app-table-controls-page input {
    width:          40px;
    border:         1px solid #ddd;
    border-radius:  3px;
    font-size:      15px;
    height:         24px;
    text-align:     right;
    padding-right:  5px;
}

.app-table-controls-pages {
    flex:               auto;
    border:             2px solid white;
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
    color:              #213547;
    font-weight:        bold;
    cursor:             pointer;
}

.app-table-controls-pages-inactive {
    color:          darkgrey;
    font-weight:    normal;
}

.app-table-controls-pages-rows select {
    border:         1px solid #ddd;
    border-radius:  3px;
    font-size:      15px;
    height:         24px;
    text-align:     right;
    padding-right:  5px;
}

.app-table-filter {
    display:        flex;
    flex-direction: row;
}

.app-table-filter-input {
    height: 32px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0px 5px;
}

.app-table-filter-td {
    background-color:   #f6f6f6;
    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    border-right:       1px solid #ddd;
    border-bottom:      1px solid #ddd;
}

.app-table-filter-td:last-child {
    border-right: 0px;
}

.app-table-border-inline .app-table-filter-td {
    border-top: 0;
}

.app-table-header {
    display:        flex;
    flex-direction: row;
    border-bottom:  1px solid #ddd;
}



.app-table-border-inline .app-table-header {
    border: 0;
}

.app-table-inner {
    overflow: auto;
}

.app-table-row,
.app-table-row-sin {
    width: 100%;
    display: flex;
    flex-direction: row;
    cursor: default;
}

.app-table-row:hover, .app-table-row:nth-child(odd):hover .app-table-td {
    background-color: rgba(22, 149, 191, 0.2);
}

.app-table-row:nth-child(odd) .app-table-td {
    background-color: #f6f6f6;
}

.app-table-td {
    border-right:       1px solid #ddd;
    border-bottom:      1px solid #ddd;
    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    padding-left:       15px;
    padding-right:      15px;
    min-height:         45px;
}

.app-table-td:last-child {
    border-right: 0px;
}

.app-table-border-inline .app-table-td:first-of-type,
.app-table-border-inline .app-table-filter-td:first-of-type {
    border-left: 1px solid #ddd;
}

.app-table-border-inline .app-table-td:last-of-type,
.app-table-border-inline .app-table-filter-td:last-of-type {
    border-right: 1px solid #ddd;
}

.app-table-td-action {
    display:        flex;
    flex-direction: row;
    align-items:    center;
    color:          rgb(22, 149, 191);
    cursor:         pointer;
    position:       relative;
}

.app-table-td-action span:first-child {
    border:         1px solid rgb(22, 149, 191);
    padding:        0;
    margin:         0;
    font-size:      13px;
    width:          20px;
    height:         20px;
    border-radius:  12px;
    text-align:     center;
    margin-right:   5px;
}

.app-table-td-action span:last-child {
    font-size: 11px;
}

.app-table-td-action_menu {
    display:            none;
    position:           absolute;
    background-color:   white;
    box-shadow:         0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
                        0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
                        0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
                        0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
    width:              220px;
    padding:            15px;
    top:                34px;
    right:              18px;
    border:             1px solid #ccc;
    z-index:            10;
}

.app-table-td-action_menu h4 {
    font-size: 14px;
}

.app-table-td-action_menu span:first-child {
    border:         0;
    font-size:      15px;
    position:       relative;
    top:            1px;
    margin-right:   10px;
}

.app-table-td-action_menu ul {
    list-style-type: none;
}

.app-table-td-action_menu ul li {
    margin-top: 10px;
    color: rgb(33,53,71)
}

.app-table-th {
    padding:        12px 20px;
    color:          #213547;
    font-weight:    600;
    border-right:   1px solid #ddd;
    cursor:         pointer;
}

.app-table-border-inline .app-table-th {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.app-table-border-inline .app-table-th:first-of-type {
    border-left: 1px solid #ddd;
}

.app-table-border-inline .app-table-th:last-of-type {
    border-right: 1px solid #ddd;
}

.app-table-th:last-child {
    border-right: 0px;
}

.app-table-th-active-asc,
.app-table-border-inline .app-table-th-active-asc {
    border-top: 2px solid rgb(22, 149, 191);
}

.app-table-th-active-desc,
.app-table-border-inline .app-table-th-active-desc {
    border-bottom: 2px solid rgb(22, 149, 191);
}

.app-table-th-no_action {
    cursor: default;
}

.form-control.app-table-filter-input, .form-select.app-table-filter-input {
    width: calc(100% - 1.5rem);
}

.input_tag-outer_div {
    width: 100%;
}

.input_tag-list_outer {
    position: relative;
    width: 100%;
    display: none;
}

.input_tag-list-inner {
    border: 1px solid #ced4da;
    max-height: 160px;
    overflow: auto;
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 101;
}

.input_tag-list_item {
    cursor: default;
    padding: 3px 10px;
}

.input_tag-list_noitem {
    padding: 3px 10px;
    cursor: not-allowed;
    font-style: italic;
}

.input_tag-list_item:hover {
    background-color: rgb(29, 182, 235);
    color: white;
}

.input_tag-tag_div {
    position: relative;
    padding-top: 10px;
    padding-bottom: 20px;
}

.input_tag-tag_div:empty {
    display: none;
}

.input_tag-tag_div:not(:empty) {
    display: block;
}

.input_tag-tag {
    background-color: rgb(29, 182, 235);
    border-radius: 30px;
    color: white;
    font-size: 12px;
    padding: 3px 10px;
    margin-top: 5px;
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
}

.input_tag-tag_close {
    margin-left: 6px;
}