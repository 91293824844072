// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: url('../utils/images/logo.svg');
}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  background: url('../utils/images/logo.svg');
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}

.login-logo {
  margin-top: 20px;
  margin-bottom: 30px;
}

.login-error-div {
  min-height: 15px;
}

.kse-buttons-primary {
  background-color: #1db6eb;
  border-color: #1db6eb;
}

.kse-buttons-primary:hover {
  background-color: #00a6db;
  border-color: #00a6db;
}

.btn-link {
  color: #1db6eb;
}

.login-error {
  padding: 10px;
  background-color: #eee;
  border: 1px solid #c66;
  margin-bottom: 15px;
  text-align:center;
  color: #c66;
}

.rt-resizable-header-content {
  color: #1db6eb;
}

.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: inset 0 3px 0 0 #b1cd4d !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #b1cd4d !important;
}

.ReactTable .-pagination .-btn {
  color: #1db6eb !important;
}

.ReactTable .-pagination .-btn[disabled] {
  color: #bbb !important;
}

.dropdown-menu .dropdown-item:hover {
  color: #b1cd4d;
}

.vertical-nav-menu .metismenu-container .metismenu-container.visible > .metismenu-item > .metismenu-link.active {
  color: #6c757d;
  background: #e0f3ff;
}

.app-sidebar__heading {
  color: #b1cd4d;
}

.btn-new-entity {
  background-color: #b1cd4d;
  border-color: #b1cd4d;
}

.btn-new-entity:hover {
  background-color: #c1dd5d;
  border-color: #c1dd5d;
}

.dialog-error{
  border: 2px solid rgba(220, 53, 69, 0.4);
  background-color: rgba(220, 53, 69, 0.3);
  color: #333;
  padding: 10px;
  margin-bottom: 20px;
}